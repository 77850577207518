


















import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'

@Component
export default class RefreshChats extends Vue {
  @Prop({ type: Boolean, required: true }) haveChats!: boolean

  updateChatsInfo() {
    this.$store.dispatch('requestChatsInfo', this)
  }
}
