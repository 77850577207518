import { render, staticRenderFns } from "./ChatCard.vue?vue&type=template&id=bd69c500&scoped=true&"
import script from "./ChatCard.vue?vue&type=script&lang=ts&"
export * from "./ChatCard.vue?vue&type=script&lang=ts&"
import style0 from "./ChatCard.vue?vue&type=style&index=0&id=bd69c500&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bd69c500",
  null
  
)

export default component.exports