

















































































































































































import BotIsNotAdminMixin from '@/mixins/BotIsNotAdminMixin'
import ChatLicense from '../../includes/logic/ChatLicense'
import themeConfig from '@/config'

import EmptyData from 'piramis-base-components/src/components/EmptyData/EmptyData.vue'
import Icon from 'piramis-base-components/src/components/Icon/Icon.vue'
import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'

import VueApexCharts from 'vue-apexcharts'
import { Component, Mixins, Prop } from 'vue-property-decorator'

@Component({
  components: {
    VueApexCharts,
    EmptyData,
    Icon
  }
})
export default class ChatCard extends Mixins<BotIsNotAdminMixin>(BotIsNotAdminMixin) {
  @Prop({ type: Object, required: true }) chat!: any

  @Prop({ type: Object, required: false }) statistics!: any

  @Prop({ type: Boolean, required: true }) statisticsLoaded!: boolean

  themeConfig = themeConfig

  chatLicense = new ChatLicense(this.chat)

  buyLink = {
    name: 'buy',
    params: { [EntityTypes.CHAT_ID]: this.chat.chat_id },
  }

  statisticsLink = {
    name: 'statistics',
    params: { [EntityTypes.CHAT_ID]: this.chat.chat_id },
  }

  chartOptions = {
    grid: {
      show: false,
      padding: {
        left: 0,
        right: 0,
      },
    },
    chart: {
      animations: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: 2.5,
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 0.9,
        opacityFrom: 0.7,
        opacityTo: 0,
        stops: [ 0, 100 ],
      },
    },
    xaxis: {
      lines: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      labels: { show: false },
    },
    yaxis: [ {
      show: false,
      y: 0,
      offsetX: 0,
      offsetY: 0,
      padding: {
        left: 0,
        right: 0,
      },
    } ],
    tooltip: {
      x: { show: false },
    },
  }

  get chatButtonTitle() {
    return this.$t('button_go_to_chat')
  }

  get chatRoute() {
    return {
      name: 'dashboard',
      params: {
        [EntityTypes.CHAT_ID]: this.chat.chat_id,
      },
    }
  }

  get chatCardTitleIcon():string {
    if (this.chat.type === 'forum') {
      return 'forum'
    }

    return 'chat'
  }

  getLicenseAvatar(licenseType: any) {
    try {
      return themeConfig.getTariff(licenseType).img
    } catch (e) {
      console.error(e)
      return ''
    }
  }
}

