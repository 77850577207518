<template>
  <div id="demo-basic-card">
    <div>
      <RefreshChats
        class="w-full h-full flex mb-5"
        :class="{'flex-row items-center justify-center lg:justify-end': haveChats, 'flex-col items-center justify-center': !haveChats}"
        :haveChats="haveChats"
      />

      <a-row
        :gutter="[16,16]"
        class="mb-5"
        type="flex"
      >
        <a-col
          v-for="(chat, key) in hiddenChats"
          :key="`chat-${key}-${chat.chat_id}`"
          :xs="24"
          :sm="12"
          :md="12"
          :xl="8"
        >
          <chat-card
            :chat="chat"
            statistics-loaded
          />
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import RefreshChats from "@/components/RefreshChats";
import makeId       from "@/assets/utils/makeId";
import ChatCard from "@/components/vu-components/ChatCard";

export default {
  data() {
    return {
      haveChats: true,
    };
  },
  components: {
    ChatCard,
    RefreshChats,
  },
  methods: {
    makeId,
  },
  watch     : {
    "$store.state.chatState.chatsInfo.chats"() {
      const chats   = this.chatsInfo;
      let havechats = false;

      if(chats !== undefined) {
        chats.map((chat) => {
          if(chat.hidden === true) havechats = true;
        });
      }

      this.haveChats = havechats;
    },
  },
  computed  : {
    chatsInfo() {
      return this.$store.state.chatState.chatsInfo.chats;
    },
    hiddenChats() {
      if (this.chatsInfo) {
        return this.chatsInfo.filter(c => c.hidden)
      }

      return []
    }
  },
  created() {
    this.$baseTemplate.saveButton.hide();
  },
  destroyed() {
    this.$baseTemplate.saveButton.show();
  },
  // mounted() {
  //   this.$store.dispatch("requestChatsInfo", this);
  // },
};
</script>
